/* @import "node_modules/ol/ol.css"; */

html,
body {
  margin: 0;
  height: 100%;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.custombuttons {
  position: absolute;
  top: 90px;
  left: 0.5em;
  display: flex;
  flex-direction: column;
  z-index: 1000; /* Adjust as needed */
}

.btngroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 10px;
  top: 150px;
  z-index: 1000; /* Adjust as needed */
}

.btngroup button {
  margin-bottom: 7px;
}

#version {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  padding: 10px;
  font-size: 14px;
  color: #333;
  z-index: 1000;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}
.hidden {
  display: none;
}

#sectionLegendList {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9); /* Slightly more opaque */
  padding: 15px; /* More padding */
  border: 1px solid #ccc; /* Lighter border color */
  border-radius: 10px; /* Larger border radius */
  list-style-type: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); /* Box shadow for depth */
  font-family: 'Arial', sans-serif; /* Different font */
}

#sectionLegendList li {
  display: flex; /* Use Flexbox */
  align-items: center; /* Center items vertically */
  margin-bottom: 5px; /* Add some space between items */
}

#sectionLegendList li span {
  margin-right: 10px; /* Add some space between the color square and the text */
}

#sectionLegendHeader {
  font-size: 16px; /* Adjust as needed */
}

#sectionLegendList li.active {
  background-color: #f0f0f0; /* Light gray background */
  color: #000; /* Black text */
  font-weight: bold; /* Bold text */
}

.burger-menu {
  font-size: 30px;
  cursor: pointer;
  padding: 10px 15px; /* Adjust padding for button-like appearance */
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #007bff; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.burger-menu:hover {
  background-color: #0056b3; /* Darker background color on hover */
}

#layerList {
  position: fixed;
  top: 50px;
  right: 10px;
  width: 250px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: none; /* Initially hidden */
}

.hidden {
  display: none;
}